.welcome-popup {
    width: 100%;
    max-width: 480px !important;
    height: 100%;
    padding: 24px;
    background: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;


    .welcome-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 16px;
    }
    
    .welcome-title {
      width: 100%;
      color: black;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      word-wrap: break-word;
    }
    
    .welcome-message {
      width: 100%;
      color: black;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: 28.8px;
      word-wrap: break-word;
    }
    
    .welcome-note {
      width: 100%;
      color: #34495E;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      word-wrap: break-word;
    }
    
    .welcome-button {
      width: 100%;
      padding: 14px 24px;
      background: #165D31;
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
    
    .button-text {
      color: white;
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      line-height: 21px;
      word-wrap: break-word;
    }
    
  }
  
  @media (max-width: 600px) {
    .welcome-popup {
      padding: 16px;
    }
  
    .welcome-title, .welcome-message, .welcome-note {
      font-size: 14px;
    }
  
    .welcome-button {
      padding: 10px 20px;
    }
  
    .button-text {
      font-size: 12px;
    }
  }
  