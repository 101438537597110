.footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px;
    background: var(--light-grey-background-alt2);

    .footer-content {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;

      .footer-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        .footer-logo {
          width: 94px;
          height: 90px;
        }

        .footer-socials {
          display: flex;
          gap: 16px;

          .footer-social-icon {
            width: 40px;
            height: 40px;
            cursor: pointer;
          }
        }
      }

      .footer-bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .footer-divider {
          width: 100%;
          height: 1px;
          background: #e4e4da;
        }

        .footer-bottom-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 30px;
          width: 100%;
          max-width: 1200px;

          .footer-copy {
            color: #5a6e82;
            font-size: 12px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
          }

          .footer-links {
            display: flex;
            gap: 32px;

            .footer-link {
              color: #5a6e82;
              font-size: 12px;
              font-family: 'Poppins', sans-serif;
              font-weight: 400;
              line-height: 18px;
              text-decoration: none;
            }
          }
        }
      }
    }
}

@media (max-width: 768px) {
  .footer-container {
    padding: 16px;

    .footer-content {
      .footer-top {
        flex-direction: column;
        align-items: center;

        .footer-socials {
          flex-direction: column;
          gap: 8px;
        }
      }

      .footer-bottom-content {
        flex-direction: column;
        text-align: center;

        .footer-links {
          flex-direction: column;
          gap: 8px;
        }
      }
    }
  }
}
