.hero-container {
    position: relative;
    overflow: hidden;
  }
  
  .hero-container .common-container {
    position: relative;
  }
  
  .hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .hero-image {
    width: 100vw;
    height: auto;
    object-fit: cover;
    /* max-height: 91vh; */
  }
  
  .hero-arrow-right, .hero-arrow-left {
    width: 48px;
    height: 48px;
    padding: 12px;
    background: var(--main-color-alt);
    justify-content: center;
    align-items: center;
    display: flex;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
  
  .hero-arrow-right {
    right: 20px;
    z-index: 1;
  }
  
  .hero-arrow-left {
    left: 20px;
    z-index: 1;
  }
  
  .hero-text-container {
    z-index: 1;
    max-width: 570px;
    margin: auto;
    padding: 20px;
    border-radius: 8px;
    position: absolute;
    left: 0;
    text-align: left;
  }
  
  .hero-text-content {
    margin-bottom: 20px;
  }
  
  .hero-title {
    color: var(--white-color);
    font-size: 48px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.5;
  }
  
  .hero-description {
    color: var(--white-color);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 1.5;
  }
  
  .hero-button {
    display: inline-flex;
    padding: 16px 64px;
    background: var(--dark-green-color);
    border-radius: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
  }
  .hero-button:hover {
    background-color: var(--hover-green-color);
  }
  .hero-button-text {
    color: var(--main-color-alt);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
  }
  
  @media (max-width: 768px) {
    .hero-title {
      font-size: 36px;
    }
  
    .hero-description {
      font-size: 14px;
      max-width: 250px;
    }
  
    .hero-button {
      padding: 12px 32px;
    }
  
    .hero-arrow-right, .hero-arrow-left {
      display: none; /* Hide arrows on smaller screens */
    }
  }
  
  @media (max-width: 480px) {
    .hero-text-container {
      left: 0;
    }
    .hero-image {
      aspect-ratio: 1;
    }
    .hero-title {
      font-size: 24px;
    }
  
    .hero-description {
      font-size: 10px;
      max-width: 170px;
    }
  
    .hero-button {
      padding: 10px 20px;
    }
  }
  