.contactUs-container {
    padding: 60px 0;
  
    .contactUs-content {
      width: 100%;
      max-width: 1200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 32px;
  
      .contactUs-header {
        text-align: center;
  
        .contactUs-title {
          color: var(--black-color);
          font-size: 40px;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          line-height: 60px;
          margin-bottom: 16px;
        }
  
        .contactUs-description {
          color: #5a6e82;
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          line-height: 32px;
        }
      }
  
      .contactUs-main {
        display: flex;
        flex-direction: column;
        gap: 30px;
        width: 100%;
  
        .contactUs-form-container {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 30px;
          width: 100%;
  
          .contactUs-form {
            display: flex;
            flex-direction: column;
            gap: 24px;
            flex: 1;
            border-radius: 4px;
            border: 1px #e2e8f0 solid;
            padding: 32px;
            min-width: 300px;
  
            .contactUs-form-group {
              display: flex;
              flex-direction: column;
              gap: 8px;
  
              .contactUs-form-label {
                color: var(--black-color);
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 600;
                line-height: 24px;
  
                .required {
                  color: #e3281c;
                }
              }
  
              .contactUs-form-input, 
              .contactUs-form-textarea {
                width: 100%;
                padding: 14px;
                background: white;
                border-radius: 4px;
                border: 1px #e2e8f0 solid;
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
              }
  
              .contactUs-form-textarea {
                height: 230px;
              }
            }
  
            .contactUs-form-submit {
              width: 100%;
              padding: 16px;
              background: #165d31;
              border-radius: 4px;
              color: white;
              font-size: 16px;
              font-family: 'Poppins', sans-serif;
              font-weight: 600;
              text-align: center;
              cursor: pointer;
              border: none;
            }
            .contactUs-form-submit:hover {
              background: var(--hover-green-color);
            }

            .submit-result {
              margin-top: 10px;
              padding: 10px;
              border-radius: 4px;
            }
            
            .submit-result.success {
              background-color: #d4edda;
              color: #155724;
              border: 1px solid #c3e6cb;
            }
            
            .submit-result.error {
              background-color: #f8d7da;
              color: #721c24;
              border: 1px solid #f5c6cb;
            }
          }
  
          .contactUs-image {
            width: 570px;
            height: 624px;
            border-radius: 6px;
            max-width: 100%;
          }
        }
  
        .contactUs-info {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 30px;
          width: 100%;
  
          .contactUs-info-item {
            height: 97px;
            padding: 24px;
            background: var(--light-grey-background-alt2);
            border-radius: 6px;
            display: flex;
            align-items: center;
            gap: 16px;
  
            .contactUs-info-icon {
              width: 49px;
              height: 49px;
              background: #062311;
              border-radius: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
  
            .contactUs-info-text {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 4px;
              color: var(--black-color);
  
              .contactUs-info-title {
                text-align: center;
                color: #222222;
                font-size: 16px;
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                line-height: 24px;
              }
  
              .contactUs-info-detail {
                text-align: center;
                color: #5a6e82;
                font-size: 14px;
                font-family: 'Poppins', sans-serif;
                font-weight: 400;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
  
  /* Responsive styles */
  @media (min-width: 481px) and (max-width: 768px) {
    .contactUs-container {
      padding: 16px;
  
      .contactUs-content {
        .contactUs-description {
          width: 100%;
        }
  
        .contactUs-form-container {
          flex-direction: column;
        }
  
        .contactUs-image {
          width: 100%;
          height: auto;
        }
  
        .contactUs-info {
          grid-template-columns: repeat(2, 1fr) !important;
          gap: 20px !important;
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .contactUs-info {
      grid-template-columns: 1fr !important;
      gap: 20px !important;
    }
  }
  