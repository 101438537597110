/* Coaches Container */
.coaches-container {
    padding: 60px 0;
    
    /* Common Container */
    .common-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
  
      /* Coaches Content */
      .coaches-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        position: relative;
  
        /* Coaches Header */
        .coaches-header {
          margin-bottom: 24px;
  
          .coaches-title {
            color: var(--black-color);
            font-size: 32px;
            font-family: var(--font-main);
            font-weight: 700;
            line-height: 48px;
          }
  
          .coaches-description {
            max-width: 720px;
            margin: 0 auto;
            color: var(--text-color);
            font-size: 14px;
            font-family: var(--font-main);
            font-weight: 400;
            line-height: 28px;
          }
        }
  
        /* Coaches List */
        .coaches-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 24px;
          flex-wrap: wrap;
  
          .coach-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 12px;
            width: 200px;
            height: 150px;
            background: var(--light-grey-background-alt2);
            aspect-ratio: 1.5;
            padding: 15px;
            border-radius: 4px;
            
            .number {
              font-size: 24px;
              font-weight: 700;
              line-height: 36px;
            }
            .name {
              font-size: 16px;
              line-height: 24px;
              font-weight: 500;
            }
  
            .coach-image {
              width: 170px;
              height: 170px;
              border-radius: 6px;
              object-fit: cover;
            }
  
            .coach-info {
              text-align: center;
  
              .coach-name {
                color: var(--black-color);
                font-size: 16px;
                font-family: var(--font-main);
                font-weight: 600;
              }
  
              .coach-role {
                color: #64748B;
                font-size: 14px;
                font-family: var(--font-main);
                font-weight: 400;
              }
            }
          }
        }
  
        /* Navigation Arrows */
        .navigation-arrows {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding-top: 20px;
          position: absolute;
          top: 70%;
        }
  
        .arrow-left,
        .arrow-right {
          padding: 8px;
          border-radius: 4px;
          border: 1px solid var(--light-color);
          cursor: pointer;
          position: absolute; /* Position the arrows absolutely */
          top: 50%; /* Center arrows vertically */
          transform: translateY(-50%); /* Adjust for perfect centering */
        }
  
        .arrow-left {
          left: 0; /* Position left arrow on the left */
        }
  
        .arrow-right {
          right: 0; /* Position right arrow on the right */
        }
  
        .arrow-icon {
          width: 24px;
          height: 24px;
          background: var(--black-color);
        }
  
        .arrow-left .arrow-icon {
          mask: url('../../images/left-arrow.svg') no-repeat center;
          mask-size: contain;
        }
  
        .arrow-right .arrow-icon {
          mask: url('../../images/right-arrow.svg') no-repeat center;
          mask-size: contain;
        }
      }
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .coaches-container .common-container .coaches-content .coaches-list {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 480px) {
    .coaches-container .common-container .coaches-content .coaches-header .coaches-title {
      font-size: 24px;
    }
  
    .coaches-container .common-container .coaches-content .coaches-header .coaches-description {
      font-size: 12px;
    }
  
    .coaches-container .common-container .coaches-content .coaches-list .coach-card {
      width: 100%;
    }
  }
  