.aboutUs-container {
    position: relative;
  
    .common-container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 24px;
  
      .aboutUs-header {
        text-align: center;
        background-color: var(--light-grey-background-alt2);
        padding: 64px 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 165px;
        z-index: 1;
  
        h1 {
          font-size: 32px;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          margin: 0;
        }
      }
  
      .aboutUs-content {
        position: relative;
        top: 165px;
        margin-bottom: 165px;
  
        .whyThePalms-header {
          width: 970px;
          color: var(--text-color-alt2);
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          font-weight: 700;
          line-height: 24px;
          word-wrap: break-word;
          margin-bottom: 10px; /* Adjust as needed for spacing */
          margin-top: 24px;
          max-width: 100%;
        }
  
        .whyThePalms-list {
          width: 970px;
          color: var(--text-color-alt2);
          font-size: 16px;
          font-family: 'Poppins', sans-serif;
          font-weight: 400;
          line-height: 32px;
          list-style-type: none; /* Remove default bullet points */
          padding-left: 0; /* Remove default padding */
          margin-bottom: 24px !important;
          max-width: 100%;
  
          li {
            margin-bottom: 10px; /* Add spacing between list items */
            position: relative;
            padding-left: 20px; /* Adjust as needed */
  
            &::before {
              content: '•'; /* Custom bullet point */
              position: absolute;
              left: 0;
              color: var(--text-color-alt2); /* Same color as the text */
              font-weight: bold;
            }
          }
        }
  
        .aboutUs-section.aboutUs-intro {
          padding: 0 !important;
          border: unset !important;
          border-radius: unset !important;
        }
  
        .aboutUs-section {
          margin-bottom: 20px;
          border: 1px solid var(--border-color);
          border-radius: 8px;
  
          hr {
            border: 1px solid var(--border-color);
            margin: auto;
            width: 95%;
          }
  
          h2 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            font-size: 18px;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            color: var(--text-color-alt2);
            padding: 20px;
          }
  
          p {
            font-size: 16px;
            font-family: 'Poppins', sans-serif;
            font-weight: 400;
            color: var(--text-color-alt2);
            line-height: 32px;
            padding: 20px;
          }
        }
      }
    }
  
    .arrow {
      display: inline-block;
      width: 10px;
      height: 10px;
      background-image: url('../../images/left-arrow.svg');
      background-size: contain;
      background-repeat: no-repeat;
      transform: rotate(-90deg);
      transition: transform 0.2s;
  
      &.open {
        transform: rotate(90deg);
      }
    }
  }
  