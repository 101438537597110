/* Partners Container */
.partners-container {
    padding: 80px 0;
  
    .common-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
  
      .partners-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 40px;
        
        .partners-header {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 8px;
  
          .partners-title {
            color: var(--black-color);
            font-size: 32px;
            font-family: var(--font-main);
            font-weight: 700;
            line-height: 48px;
            text-align: center;
          }
  
          .partners-description {
            max-width: 720px;
            color: var(--text-color-alt2);
            font-size: 14px;
            font-family: var(--font-main);
            font-weight: 400;
            line-height: 28px;
            text-align: center;
          }
        }
  
        .partners-list {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
            justify-content: space-between;
  
          .arrow {
            padding: 8px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
  
            .arrow-icon {
              width: 24px;
              height: 24px;
              background: var(--black-color);
              mask-size: contain;
              mask-repeat: no-repeat;
            }
  
            &.left-arrow .arrow-icon {
              mask-image: url('../../images/left-arrow.svg');
            }
  
            &.right-arrow .arrow-icon {
              mask-image: url('../../images/right-arrow.svg');
            }
          }
  
          .partners-images {
            display: flex;
            gap: 16px;
            overflow-x: auto;
            max-width: 100%;

            .partner-image {
              width: 170px;
              height: 75px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .common-container {
      .partners-content {
        .partners-header {
          .partners-title {
            font-size: 24px;
          }
  
          .partners-description {
            font-size: 12px;
            max-width: 90%;
          }
        }
  
        .partners-list {
          flex-direction: column;
          align-items: center;
  
          .partners-images {
            .partner-image {
              width: 150px;
              height: auto;
            }
            max-width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
  
  @media (max-width: 480px) {
    .common-container {
      .partners-content {
        .partners-header {
          .partners-title {
            font-size: 20px;
          }
  
          .partners-description {
            font-size: 10px;
          }
        }
  
        .partners-list {
          .partners-images {
            .partner-image {
              width: 120px;
            }
            max-width: 100%;
            overflow: hidden;
          }
        }
      }
    }
  }
  