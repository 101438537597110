.adultPopup-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: white;
    border-radius: 10px;
    width: 720px;
    max-width: 100%;
    
    .scrollable-box {
        max-height: 500px;
        padding: 0 20px;
    }
    
    
    .program-details {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    
    .program-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 12px;
      background: #F6F6F5;
      border-radius: 8px;
    }

    .program-item.p-title {
        padding: 12px 0;
        background: unset;
    }
    
    .program-item .title {
      color: black;
      font-size: 16px;
      font-weight: 600;
    }
    
    .program-item .details {
      display: flex;
      align-items: stretch;
      gap: 8px;
    }
    
    .program-item .details .icon {
      width: 71px;
      background: #FA0B7D;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .program-item .details .icon.men {
      background: #1950DE;
    }
    
    .program-item .details .icon div {
      width: 24.84px;
      height: 28px;
      background: white;
    }
    
    .program-item .details .info {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
    
    .program-item .details .info div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
    
    .program-item .details .info div span {
      font-size: 14px;
    }
    
    .program-item .details .info div .label {
      color: black;
      font-weight: 550;
      font-size: 12px;
    }
    
    .program-item .details .info div .value {
      color: #34495E;
      font-weight: 400;
    }
    
    .program-item .price {
      font-size: 12px;
    }
    
    .program-item .price .label {
      color: #34495E;
      font-weight: 400;
    }
    
    .program-item .price .value {
      color: black;
      font-weight: 600;
    }
    
    .program-item .price .intro {
      color: #34495E;
      font-weight: 400;
      margin-right: 5px;
    }
    
    .divider {
      height: 1px;
      background: #E4E4DA;
      margin: 20px 0 10px 0;
    }
    
  }
  
  /* section header style */

  .section-header-adultPopup-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background: var(--white-color);
    padding: 20px;
    height: 56px;
  }
  .section-header-adultPopup-container .title {
    color: black;
    font-size: 21px;
    font-weight: 700;
  }
  
  .section-header-adultPopup-container .icon {
    width: 24px;
    height: 24px;
    position: relative;
    cursor: pointer;
  }
  
  .section-header-adultPopup-container .icon::after {
    content: '';
    width: 13.15px;
    height: 13.15px;
    position: absolute;
    top: 5.42px;
    left: 5.42px;
  }