.about-container {
    padding: 60px 0; /* Add padding for top and bottom spacing */
  }
  
  .about-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    text-align: center;
  }
  
  .about-title {
    color: var(--black-color);
    font-size: 32px;
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    line-height: 1.5;
  }
  
  .about-description {
    color: var(--text-color-alt2);
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    line-height: 2;
    margin: 0 auto;
    text-align: start;
  }

  .read-more-text {
    /* color: var(--link-blue-color); */
  }
  
  @media (max-width: 768px) {
    .about-title {
      font-size: 28px;
    }
  
    .about-description {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  
  @media (max-width: 480px) {
    .about-title {
      font-size: 24px;
    }
  
    .about-description {
      font-size: 12px;
    }
  }
  