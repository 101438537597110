.programs-container {
    padding: 60px 0;
    
    .programs-header {
      text-align: center;
      margin-bottom: 24px;
    }
    
    .programs-title {
      color: var(--black-color);
      font-size: 32px;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      line-height: 48px;
    }
    
    .programs-description {
      max-width: 720px;
      margin: 0 auto;
      color: var(--text-color);
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      line-height: 28px;
    }
    
    .programs-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: 24px;
    }
    
    .programs-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      flex: 1;
    }
    
    .program-card {
      padding: 16px;
      border-radius: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: white;
      cursor: pointer;
      transition: 0.3s;
      border: 1px solid #CDD3CF;
    }
  
    .program-card.active {
      background: var(--black-color);
      .program-icon {
        background: var(--white-color);
      }
      .program-number {
        color: var(--black-color);
      }
      .program-name {
        color: var(--white-color);
      }
      .program-arrow path {
        fill: var(--white-color);
      }
    }
    
    .program-info {
      display: flex;
      align-items: center;
      gap: 16px;
    }
    
    .program-icon {
      width: 40px;
      height: 40px;
      background: var(--black-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .program-number {
      color: var(--white-color);
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
    }
    
    .program-name {
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      color: var(--black-color);
    }
    
    .program-arrow {
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .program-arrow path {
      fill: var(--black-color);
    }
    
    .arrow-icon {
      width: 6.55px;
      height: 11.15px;
      background: white;
    }
    
    .program-details {
      flex: 2;
      padding: 24px;
      background: var(--light-grey-background-alt2);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
    }
    
    .details-title {
      color: var(--black-color);
      font-size: 24px;
      font-family: 'Poppins', sans-serif;
      font-weight: 700;
      transition: 0.3s;
    }
    
    .details-content {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 16px;
      transition: 0.3s;
    }
    
    .details-image {
      width: 174px;
      height: 174px;
      border-radius: 5px;
      transition: 0.3s;
    }
    
    .details-text {
      flex: 1;
      color: var(--black-color);
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      font-weight: 400;
      line-height: 28.8px;
      transition: 0.3s;
      min-width: 100px;
    }
    
    .details-button {
      padding: 14px 24px;
      background: var(--dark-green-color);
      border-radius: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 16px;
      cursor: pointer;
      align-self: end;
    }
  
    .details-button:hover {
      background: var(--hover-green-color);
    }
  
    .details-button path {
      fill: var(--main-color-alt);
    }
    
    .button-text {
      color: var(--main-color-alt);
      font-size: 14px;
      font-family: 'Poppins', sans-serif;
      font-weight: 600;
      line-height: 21px;
    }
    
    .button-arrow .arrow-icon {
      background: var(--main-color-alt);
    }
    
    @media (max-width: 768px) {
      .programs-content {
        flex-direction: column;
      }
    
      .programs-list,
      .program-details {
        width: 100%;
      }
    }
    
    @media (max-width: 480px) {
      .programs-title {
        font-size: 24px;
      }
    
      .programs-description {
        font-size: 12px;
      }
    
      .programs-content {
        flex-direction: column;
      }
    
      .details-title {
        font-size: 20px;
      }
    
      .details-text {
        font-size: 14px;
      }
    }
    
  }
  