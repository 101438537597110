/* FindClub Container */
.findClub-container {
    padding: 80px 0;
  
    .common-container {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
  
      .findClub-content {
        padding: 32px;
        background: #062311;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 32px;
        overflow: hidden;
  
        .findClub-header-and-links {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
  
          .findClub-header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex: 1;
            width: 300px;
            max-width: 100%;
  
            .findClub-title {
              color: white;
              font-size: 32px;
              font-family: var(--font-main);
              font-weight: 700;
              line-height: 48px;
            }
  
            .findClub-description {
              max-width: 600px;
              color: #e0e0e0;
              font-size: 14px;
              font-family: var(--font-main);
              font-weight: 400;
              line-height: 25.2px;
            }
          }
  
          .app-links {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
  
            .app-store,
            .google-play {
              width: 163px;
              height: 48px;
              cursor: pointer;
            }
          }
        }
  
        .image-stack {
          position: relative;
          width: 100%;
          max-width: 1106px;
          height: 350px;
          margin-top: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
  
          .court-man {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
          }
  
          .zoom-in-man {
            position: absolute;
            width: auto;
            height: 299px;
            border-radius: 20px 10px 0 0;
            z-index: 2;
            max-width: 210px;
            bottom: 0;
          }
  
          .mobile-frame {
            z-index: 3;
          }
        }
      }
    }
  
    /* Responsive Design */
    @media (max-width: 768px) {
      .common-container {
        .findClub-content {
          padding: 16px;
  
          .findClub-header-and-links {
            flex-direction: column;
            align-items: center;
            text-align: center;
  
            .findClub-header {
              align-items: center;
            }
  
            .app-links {
              margin-top: 16px;
              flex-direction: column;
              gap: 8px;
  
              .app-store,
              .google-play {
                width: 100%;
                max-width: 200px;
                height: auto;
              }
            }
          }
  

        }
      }
    }
  
    @media (max-width: 480px) {
      .common-container {
        .findClub-content {
          .findClub-title {
            font-size: 20px;
          }
  
          .findClub-description {
            font-size: 12px;
          }

        }
      }
    }
  }
  