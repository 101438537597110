.header-container {
    background: var(--dark-green-color);
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.10);
    padding: 12px 0;
    z-index: 6;
    position: sticky;
    top: 0;
}
  
  .header-container .common-container {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .header-container .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header-container .header-logo {
    width: 62px;
    height: 60px;
    cursor: pointer;
  }
  
  .header-container .header-links {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }
  
  .header-container .header-link {
    color: var(--white-color);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    cursor: pointer;
    /* transition: color var(--main-transition); */
  }
  
  .header-container .header-link:hover {
    color: var(--black-color);
  }
  
  .header-container .header-divider {
    width: 1px;
    height: 24px;
    background: var(--border-color);
    border-radius: 10px;
  }
  
  .header-container .header-icon {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .header-container .header-signin {
    padding: 10px 24px;
    background: var(--light-grey-background);
    border-radius: 4px;
    color: var(--dark-green-color);
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  @media (max-width: 768px) {
    .header-container .header-links {
      gap: 12px;
    }
    .header-container .header-link, 
    .header-container .header-signin {
      font-size: 12px;
      padding: 8px 12px;
    }
  }
  
  @media (max-width: 480px) {
    .header-container .header-content {
      flex-direction: column;
      align-items: flex-start;
    }
    .header-container .header-links {
      /* flex-wrap: nowrap;
      overflow-x: auto; */
      padding-top: 10px;
      gap: 10px;
    }
    .header-container .header-logo, 
    .header-container .header-link, 
    .header-container .header-signin {
      font-size: 12px;
    }
  }
  