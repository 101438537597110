
  .rulesAndRegulations-container {
    position: relative;
    
    .common-container {
      max-width: 1440px;
      margin: 0 auto;
      padding: 24px;
  
      .rulesAndRegulations-header {
        text-align: center;
        background-color: var(--light-grey-background-alt2);
        padding: 64px 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 165px;
        z-index: 1;
  
        h1 {
            font-size: 32px;
            font-family: 'Poppins', sans-serif;
            font-weight: 700;
            margin: 0;
        }
      }
  
      .rulesAndRegulations-content {
        position: relative;
        position: relative;
        top: 165px;
        padding: 20px;
        margin-bottom: 165px;

        h2 {
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 16px;
        }

        ul {
            list-style: unset;
            margin-bottom: 40px !important;

            li {
                font-size: 16px;
                line-height: 1.5;
                padding: 4px;
            }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 32px;
          margin-bottom: 32px;
        }
      }
    }
  }
  
  /* Media Queries for Responsiveness */
  @media (max-width: 768px) {
    .rulesAndRegulations-container {
      .common-container {
        padding: 12px;
  
        .rulesAndRegulations-content {
          .section {
            width: 100%;
            padding: 16px;
          }
        }
      }
    }
  }
  